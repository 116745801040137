
@import "../../../data/config-scss/variables.scss";

div.note-btn {
    margin   : .2em 0em 0;
    font-size: 1.8em;
    color    : $shareBtnColor;
    cursor   : pointer;
}
@media (min-width:768px) {
    div.note-btn{
        font-size:1em;
    }
}
