
@import "../../../data/config-scss/variables.scss";


[id=home-toolbar] {
    flex-shrink  : 0;
    display      : flex;
    margin       : 5px 0 0 1px;
    padding      : 0 2px 0 0;
    color        : $homeToolbarColor;
}


/* Toolbar button style */
.ht-button {
    display         : flex;
    flex      : 1;
    margin-top      : 1px;
    margin-bottom   : 1px;
    height          : 1.7em;
    padding-top     : .1em;
    font-size       : 1.3em;
    justify-content : center;
    background-color: $homeToolbarBackgroundColor;
    cursor          : pointer;
    transition      : background-color .4s;
    -webkit-tap-highlight-color: transparent;

    .icon-font {
        align-self: center;
        font-size  : 1.3em;
        padding-top: .07em;
    }

    img {
        margin-top: .1em;
        width: 24px;
        height: 26px;
    }
    svg, path {
        fill: $homeToolbarColor;
    }
}

.ht-icon {
    //padding-top: .3em;
    align-self: center;
    font-size  : 1em;
    top: -0.1em;
    img {
        width: 24px;
        height: 26px;
    }
}

.ht-login-icon {
    .connected {
        background-color: #5bbe5b;
    }
}
.ht-favorites-icon {

}
.ht-inbox-icon {
    //padding-top: .3em;
    //font-size  : .9em;
}
.ht-kl-icon {
    width: 1.3em;
    height: 1.3em;
    padding-bottom: .1em;
}
.ht-search-icon {

}
.ht-menu-icon {

}



.ht-button:not(:first-child) {
    margin-left: 2px;
}
.is-web .ht-button:hover {
    background-color: $homeToolbarBackgroundColorHover;
}


.ht-button-badge {
    font-size: .9em; // scale element
    height: 1em;
    min-width: 1em;
    text-align: center;
    margin-right: .3em;
    padding: .2em;
    color: $inboxCountColor;
    background-color: $inboxCountBackgroundColor;
    border-radius: 50%;
}
