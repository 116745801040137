
@import "../../data/config-scss/variables.scss";


$horizontalSeparatorBorder: 1px solid #eee;



.fiche-row1 {
    display: flex;
    margin: 1em;
}

// COLUMN 1

.fiche-row1-col1 {
    flex: 0 0 45%;
    max-width:200px;
    margin-right: 1em;
}
.fiche-photo {
    width: 100%;
}



// COLUMN 2

.fiche-row1-col2 {
    a {
        font-size: 1.8em;
    }
}

.fiche-subtitle {
    margin-bottom: 1em;
    font-size: .95em;
}
.fiche-paragraph {
    margin-bottom: 1em;
    font-size: .95em;
}
.fiche-logo {
    max-width: 120px;

    img {
        margin-top: 1em;
        max-width: 100%;
        max-height:70px;
    }
}


// ROW 2
.fiche-row2 {
    margin-bottom: 1em;

    .free-row {
        border-width: 0;
    }
}



.fiche {
    display: flex;
    flex-direction: column;
    background-color: $ficheBackgroundColor;

    // still used ?
    .list {
        border-bottom:none;
    }

    .all-informations {
        display       : flex;
        flex-direction: column;
        flex-grow     : 1;
        position      : relative;
        margin-bottom : 10%;
        background    : #fff;

        & > .prop-name {
            padding-bottom: 0.2em;
            border-bottom : 1px solid #eee;
        }
        .detail-data-list {
            min-height: fit-content;
            height: fit-content;
        }
    }

    .description {
        margin: .5em 0 0 1em;

        small {
            font-size: .8em;
        }
        div {
            margin-left: 1em;
        }
        img {
            margin-top: 1em;
            max-width : 100%;
        }
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .icon-font {
            margin-top: -4px;
            font-size : $ficheIconsSize;
            width: $ficheIconsSize;
            height: $ficheIconsSize;
            display: block;
            text-align: center;
        }
        flex-shrink: 7;
    }

    .content-font {
        font-size: 16px;
    }

    .fiche-related-data {
        margin-bottom: .3em;
        user-select: none;

        .prop-img {
            margin-left: 1em;
            padding: .4em 0;
            border-bottom: $horizontalSeparatorBorder;
        }
    }
    .fiche-related-data:first-child {
        margin-top: .5em;
    }
    .fiche-related-data:last-child {
        margin-bottom: 0;

        .prop-img {
            border-bottom: 0;
        }
    }

    .high-row .prop-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }


    .detail-exhibitor,
    .free-row {
        margin : .6em 0 0 1em;
        padding: 0 .3em .6em 0;
        border-bottom: $horizontalSeparatorBorder;
    }
    .free-row:last-child {
        border-bottom: 0;
    }

    .row-count {
        height     : 47px;
        overflow-y : hidden;
        padding-top: 3px;
    }

    .nouveautes.title-font,
    .evenements.title-font,
    .aeronefs.title-font {
        font-size: 16px;
        color    : #005EA7;
    }

    .activity {
        & > div {
            width: inherit;
        }
        &.title-font span {
            color: #005EA7;
        }
    }

    .localisations {
        float  : left;
        margin : 0;
        padding: 0;
        list-style-type: none;

        .link {
            font-size: 16px;
            color: #005EA7;
        }

        .info {
            font-size: 14px;
            color: $fontColor;
            font-style: italic;
            margin-left: 0.8em;
        }

        li {
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        li:last-child {
            margin-bottom: 7px;
        }
    }

    .prop-img {
        display: flex;

        .prop-right {
            flex-grow: 1;
        }
    }

    .prop-right {
        flex-basis: 50%;
        word-break: break-word;
    }
    .prop-right.title-font .link {
        font-size: 16px;
        color    : #005EA7;
    }

    .prop-left {
        display        : flex;
        flex-basis     : 18%;
        flex-shrink    : 0;
        justify-content: center;
        align-items    : center;
        margin-right   : 1em;

        .label {
            // fix iOS bug
            font-size: 1em !important;
        }
    }

    .prop-right-fleche {
        display        : flex;
        justify-content: center;
        align-items    : center;
        padding-right  : 2%;
        padding-left   : 10px;
    }

    .fiche-separator {
        display         : inline-block;
        width           : 100%;
        height          : 1em;
        text-align      : left;
        font-weight     : bold;
        background-color: #f2f2f2;
        padding         : 8px 0px 7px 5px;
        // text-transform  : capitalize;
        user-select     : none;
    }
    .is-fav {
        color: $favIconColor;
    }

    .content .icon-font .link {
        color      : $color2;
        margin-left: 10px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: block;
    }
    span.fa {
        width    : 0.7em;
        font-size: 18px;
        color    : #a5a5a5;
    }
    span.addressName {
        display: block;
    }

    .chevron-right-icon:before {
        background-color: $chevronColor;
    }

    .count {
        margin-right: .3em;
        font-size   : 1.8em;
        font-weight : 800;
    }
    .fleche {
        float: right;
    }
    .nouveautes {
        display: block;
    }


    .entites-content {
        padding-bottom: 7px;
        margin-top    : 5px;
        width         : 100%;
        border-bottom: $horizontalSeparatorBorder;

        &:last-child {
            border: none;
        }
    }

    .content-entities {
        width: 70%;
        float: right;
    }

    .activities-content {
        display : flex;
        padding : .3em 0 .4em;
        align-items: center;

        & > span:first-child {
            flex         : 1 1 auto;
            overflow     : hidden;
            text-overflow: ellipsis;
            min-width    : 0;
        }

        .prop-right-fleche {
            padding-left: 0;
        }
    }

    .detail-exhibitor   .activities-content {
        margin-bottom: 0;
    }

    .color-grey-dark {
        color: #595959;
        font-weight: 600;
    }

    .txt-right {
        text-align:right;
    }
}


.social-icons-container {
    font-size: 1.8em;
    color: $color1;

    a {
        margin-right: .5em;
    }
}


@media only screen and (min-width: 768px) {
    .fiche {
        .title-font {
            font-size: 24px;
        }
        .title .share-btn {
            margin-top: .2em;
        }
        .title .note-btn {
            margin-top: .2em;
        }
        .content-font {
            font-size: 22px;
        }
        .content .icon-font {
            width    : 46px;
            height   : 46px;
            font-size: 46px;
        }
        p {
            font-size: 22px;
            line-height: 26PX;
        }
        .prop-img .prop-right {
            line-height: 26PX;
        }
        .prop-right.title-font .link {
            font-size: 22px;
        }
        .prop-left {
            display: flex;
            align-items: center;
            width: 110px;
        }
        .localisations .link {
            font-size  : 22px;
            line-height: 26PX;
        }
    }

}
